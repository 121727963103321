<template>
  <span class="loader">
    <span class="loader__dot pulse pulse__one" />
    <span class="loader__dot pulse pulse__two" />
    <span class="loader__dot pulse pulse__three" />
  </span>
</template>

<script>
export default {
  props: {}
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

$dot-size: 10px;

.loader {
  position: relative;
  display: inline-block;
}

.loader__dot {
  display: block;
  width: $dot-size;
  height: $dot-size;
  margin: ($dot-size/2.5);
  border-radius: ($dot-size/2);
  background-color: currentColor;
  float: left;
}
// Pulse animation
.pulse {
  will-change: transform, opacity;
  animation: pulse 1.25s infinite ease;
}
.pulse {
  &__one {
    animation-delay: 250ms;
  }
  &__two {
    animation-delay: 500ms;
  }
  &__three {
    animation-delay: 750ms;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.25);
  }
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}
</style>
